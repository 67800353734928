/* Contact form CSS */
#contact {
  display: flex;
  height: 700px;
  margin-bottom: 90px;
}

#contact .display {
  margin-bottom: 25px;
}

#contact .header1 {
  margin-bottom: 25px 0px 25px 0px;
}

#contact .left-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

#contact .left-container .headings {
  height: 550px;
}

#contact .right-container {
  display: flex;
  justify-content: flex-end;
  width: 50%;
  align-items: flex-end;
  flex-direction: column;
}

form {
  display: flex;
  flex-direction:column;
  width: 90%;
  padding: 5px;
}

input {
  padding: 12px;
  margin: 5px 0px 5px 0px;
}

input[type="text"], textarea
{
    font-family:"HelveticaNeueLight";
}



textarea {
  padding: 12px;
  margin: 5px 0px 5px 0px;
  resize: none;
  height: 300px;
}

.links .linkedin {
  margin: 20px 20px 20px 0px;
  width: 60px;
  height: 60px;
}

.links .instagram {
  margin: 20px 0px 20px 0px;
  width: 60px;
  height: 60px;
}

#contact button {
  color: white;
  background-color: black;
}

@media screen and (max-width:1000px) {
  #contact {
    flex-direction: column;
  }
  #contact .display, #contact .header1 {
    margin: 20px 0px 20px 0px;
    width: 100%;
  }
  #contact .left-container .headings {
   display: flex;
   flex-direction: column;
   height: 100%;
  }
  #contact .right-container {
    width: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media screen and (max-width:900px) {
  #contact {
    flex-direction: column;
    height: 100%;
  }
  #contact .display, #contact .header1 {
    width: 100%;
    margin: 0px;
  }
  #contact .left-container {
    margin: 0px 10px 0px 10px;
    height: 400px;
  }
  #contact .right-container {
    width: 100%;
    padding: 5px;
  }
  #contact .left-container .headings {
    height: 250px;
  }
}

@media screen and (max-width:700px) {
  #contact {
    flex-direction: column;
    height: 100%;
  }
  #contact .display, #contact .header1 {
    margin: 0px;
    width: 100%;
  }
  #contact .display {
    font-size: 6rem;
  }
  #contact .header1 {
    font-size: 2rem;
  }
  #contact .left-container .headings {
    margin: 0px;
  }
  #contact .right-container {
    width: 100%;
    padding: 5px;
  }
  #contact .left-container {
    height: 300px;
    margin-bottom: 10px;
  }
}

@media screen and (max-width:500px) {
  #contact .display {
    font-size: 5rem;
  }
  #contact .header1 {
    font-size: 2rem;
  }
  #focus-bg .placeholder {
      height: 60%;
      display: block;
      margin: auto auto auto auto;
  }
  .links .linkedin {
    margin: 20px 20px 20px 0px;
    width: 60px;
    height: 60px;
  }

  .links .instagram {
    margin: 20px 0px 20px 0px;
    width: 60px;
    height: 60px;
  }
  #contact .left-container {
    width: 100%;
    height: 300px;
  }
}

@media screen and (max-width:350px) {
  #contact .display {
    font-size: 4rem;
  }
  #contact .header1 {
    font-size: 2rem;
  }
  .links .linkedin {
    margin: 20px 20px 20px 0px;
    width: 60px;
    height: 60px;
  }
  .links .instagram {
    margin: 20px 0px 20px 0px;
    width: 60px;
    height: 60px;
  }
  #contact .left-container {
    width: 100%;
    height: 250px;
  }
}
