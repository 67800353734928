#about-me {
    display: flex;
    align-items: center;
}

#about-me .placeholder {
    width: 50%;
    margin: 20px 0px 20px 30px;
}

#about-me .description {
    width: 50%;
}

#about-me .description .paragraph, #about-me .description .subheading  {
    margin: 20px 10px 10px 0px;
}

#about-me .space {
    line-height: 100px;
}

@media screen and (max-width:900px) {
    #about-me {
        display: flex;
        flex-direction: column;
    }
    #about-me .display {
        margin: 10px 0px 10px 0px;
        font-size: 6rem;
    }
    #about-me .description {
        width: 100%;
    }
    #about-me .description .header1  {
        margin: 10px 0px 10px 0px;
        font-size: 2rem;
    }
    #about-me .description .paragraph2  {
        margin: 10px 0px 10px 0px;
    }
    #about-me .placeholder {
        width: 100%;
        margin: 20px 0px 20px 30px;
    }
}

@media screen and (max-width:500px) {
    #about-me {
        display: flex;
        flex-direction: column;
    }
    #about-me .display {
        margin: 10px 0px 10px 0px;
        font-size: 4rem;
    }
    #about-me .description {
        width: 100%;
    }
    #about-me .description .paragraph2, #about-me .description .header1  {
        margin: 10px 0px 10px 0px;
    }
    #about-me .description .header1  {
        font-size: 2rem;
    }
    #about-me .placeholder {
        width: 100%;
        margin: 20px 10px 20px 10px;
    }
}
