#focus-bg {
    width: 100%;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 100;
    position: fixed;
    top:0;
    left: 0;
    padding: 0px 20px 0px 20px;
}

#focus-bg .placeholder {
    height: 90%;
    display: block;
    margin: auto auto auto auto;
}

.btn-close {
    text-align:end;
    font-size: 40px;
    padding-right: 10%;
}

.btn-close button {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: white;
    font-size: 40px;
}

.btn-close button:hover {
    background-color: rgba(0, 0, 0, 0);
    border: none;
    color: rgba(0, 0, 0, 0.5);
    font-size: 40px;
}

@media screen and (max-width:900px) {
    #focus-bg .placeholder {
        height: 80%;
        display: block;
        margin: auto auto auto auto;
    }
}

@media screen and (max-width:500px) {
    #focus-bg .placeholder {
        height: 60%;
        display: block;
        margin: auto auto auto auto;
    }
}
