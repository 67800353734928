#landing .placeholder {
    display: flex;
    height: 296px;
    max-height: 296px;
    margin-bottom: 36px;
    width: 100vw;
}

#landing .display {
    max-width: 50%;
    margin: 0px 10px 5px 70px;
}

#landing .header1 {
    margin: 0px 10px 0px 90px;
  }

@media screen and (max-width:900px) {
    #landing .display {
      margin-left: 20px;
      font-size: 6rem;
    }

    #landing .header1 {
      margin-left: 20px;
      font-size: 2rem;
    }
}

@media screen and (max-width:500px) {
  #landing .display, #landing .header1 {
    margin-left: 20px;
    margin-right:20px;
    font-size: 4rem;
  }

  #landing .header1 {
    font-size: 2rem;
    margin-left: 25px;
  }
}
