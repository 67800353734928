@font-face {
  font-family: "HelveticaNeueMedium";
  src: local("HelveticaNeueMedium"),
  url("helvetica-neue-5/HelveticaNeueMedium.otf"),
}
@font-face {
  font-family: "HelveticaNeueLight";
  src: url("helvetica-neue-5/HelveticaNeueLight.otf");
  font-weight: light;
}

html {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
  background-color: #F0F0F0;
}

body {
  margin: 0;
  padding: 0;

  font-family: "HelveticaNeueLight", "HelveticaNeueMedium";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#spa {
  margin-left: 96px;
  margin-right: 96px;
}


a {
  text-decoration: none;
  color: black;
}
a:visited {
  text-decoration: none;
  color: black;
}
a:hover { text-decoration: none; }
a:focus { text-decoration: none; }
a:hover, a:active { text-decoration: none; }

ul {
  list-style-type: none;
}

.display {
  font-size: 130px;
  font-family: "HelveticaNeueMedium";
  font-weight: medium;
  margin: 20px 0px 20px 0px;
  color: #20202E;
}

.header1 {
  font-size: 32px;
  font-weight: light;
  font-family: "HelveticaNeueLight";
  color: #454545;
  letter-spacing: .2rem;
}

.header2 {
  font-size: 24px;
  font-weight: light;
  font-family: "HelveticaNeueLight";
  color: #F0F0F0;
}

.paragraph1 {
  font-size: 24px;
  font-weight: light;
  font-family: "HelveticaNeueLight";
  font-style: italic;
  color: #454545;
}

.paragraph2 {
  font-size: 18px;
  font-weight: light;
  font-family: "HelveticaNeueLight";
  color: #454545;
  line-height: 36px;
}

.button {
  font-family: "HelveticaNeueMedium";
  font-weight: medium;
  font-size: 18px;
}

/* .subheading, .heading2 {
  font-size: 32px;
  font-family: "HelveticaNeueLight";
  letter-spacing: .2rem;
  opacity: 60%;
  margin: 20px 0px 20px 0px;
  font-weight: lighter;
}

.paragraph {
  font-size: 24px;
  font-family: "HelveticaNeueLight";
  line-height: 36px;
} */

#landing, #about-me, #my-work, #sketchbook {
  margin-bottom: 150px;
}

.placeholder {
  background-color: #D9D9D9;
}

@media screen and (max-width:900px) {
  #spa {
    margin: 0px 20px 0px 20px;
  }
  .display {
    font-family: "HelveticaNeueMedium";
    font-weight: medium;
    color: #20202E;
  }

  .header1 {
    font-weight: light;
    font-family: "HelveticaNeueLight";
    color: #454545;
    letter-spacing: .2rem;
  }

  .header2 {
    font-weight: light;
    font-family: "HelveticaNeueLight";
    color: #F0F0F0;
  }

  .paragraph1 {
    font-weight: light;
    font-family: "HelveticaNeueLight";
    font-style: italic;
    color: #454545;
  }

  .paragraph2 {
    font-weight: light;
    font-family: "HelveticaNeueLight";
    color: #454545;
    line-height: 36px;
  }

  .button {
    font-family: "HelveticaNeueMedium";
    font-weight: medium;
  }
}

@media screen and (max-width:800px) {
  .heading1 {
    font-size: 5rem;
  }

  .subheading {
    font-size: 3rem;
  }

  .paragraph {
    font: 1rem;
  }

  #spa .heading1, #spa .subheading {
    margin: 10px 0px 10px 0px;
  }
}

@media screen and (max-width:800px) {
  #spa {
    margin: 20px;
  }
}

@media screen and (max-width:500px) {
.heading1 {
  font-size: 4rem;
}

h4 .subheading, .heading2 {
  font-size: 2rem;
}
}
