.navbar-toggle {
    display: none;
}

.nav-buttons {
    display: flex;
    justify-content: space-between;
    font-size: 24px;
}

.nav-bar {
    display: flex;
    justify-content: space-between;
    font-size: 40px;
    align-items: center;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 10px 90px 10px 90px;
    height: 97px;
}

nav {
    position: sticky;
    top: 0;
    -webkit-backdrop-filter: blur(2px);
    backdrop-filter: blur(2px);
    z-index: 100;
    background-color: rgba(240, 240, 240, .3);
}


.logo {
    font-family: "HelveticaNeueMedium";
    font-weight: bold;
}

.nav-buttons li {
    margin-right: 20px;
    margin-left: 20px;
    margin-top: 0px;
    margin-bottom: 0px;
}

.nav-buttons li:hover{
    border-bottom: 1px solid black;
}

@media screen and (max-width:650px) {
    .logo-and-toggle {
        display: flex;
        justify-content: space-evenly;
        width: 100vh;
    }
    .navbar-toggle {
        display: block;
    }

    .nav-bar {
        display: flex;
        flex-direction: column;
        text-align: center;
        padding: 20px;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        position: relative;
    }

    .nav-buttons {
        display: flex;
        flex-direction: column;
        padding: 0px;
        bottom: 0;
        right: 0;
        left: 0;
        top: -400%;
        position: relative;
    }

    .active {
        display: flex;
        flex-direction: column;
        padding: 0px;
        bottom: 0;
        right: 0;
        left: 0;
        top: 0;
        position: relative;
        background-color: rgba(240, 240, 240, .3);

    }
    .nav-bar {
        font-size: 50px;
    }
}
