#sketchbook {
    margin-bottom: 200px;
}

#sketchbook .display {
    max-width: 50%;
}
tr, td {
    padding: 20px;
}

#sketchbook .header1 {
    margin-bottom: 90px;
}

.view-all button {
    background-color: black;
    width: 100%;
    height: 30px;
}

.view-all button a {
    color: white;
}

.view-all {
    display: flex;
    justify-content: center;
    margin: 10px 10px 10px 10px;
}

.grid-container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
}

.grid-item {
    margin: 20px;
}

.grid-item img {
    width: 100%;
}

@media screen and (max-width:900px) {
    #sketchbook .heading1 {
        font-size: 7rem ;
        margin: 30px 0px 30px 0px;
    }
    #sketchbook .subheading {
        font-size: 2rem;
        margin-bottom: 20px;
    }
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}

@media screen and (max-width:768px) {
    #sketchbook .display {
        font-size: 6rem ;
    }
    #sketchbook .header1 {
        font-size: 2rem;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:700px) {
    #sketchbook .display {
        font-size: 4rem ;
    }
    #sketchbook .header1 {
        font-size: 2rem;
        margin-bottom: 20px;
    }
}

@media screen and (max-width:500px) {
    #sketchbook .display1 {
        font-size: 3rem ;
    }
    #sketchbook .header1 {
        font-size: 2rem;
        margin-bottom: 20px;
    }
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
}
