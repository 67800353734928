/* Project Block Left styling */
.project-block-right, .project-block-left {
    display: flex;
    justify-content: space-between;
    margin: 5%;
}

/* Project block right styling */
.project-block-right .date{
    justify-content: end;
}
/* Project Block styling */
.project-info {
    width: 49%;
}

/* make date container flushed to the cover letter */
.date-container {
    display: flex;
    justify-content: end;
}

.project-info .date {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #20202E;
    width: 300px;
    height: 44px;
}

.project-info .description {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 88%;
    width: 100%;
}

.description .paragraph1 {
    width: 87.5%
}

.cover-image {
    width: 49%;
    /* box shadow styling to go around entire border */
    -webkit-box-shadow: 0 0 18px rgba(0,0,0, .3);
        box-shadow: 0 0 18px rgba(0,0,0, .3);
}

#my-work .display {
    max-width: 30%;
}

@media screen and (max-width:1300px) {
    #my-work {
        display: flex;
        flex-direction: column;
    }
    #my-work .display {
        margin: 10px 0px 10px 0px;
        max-width: 100%;
    }
    #my-work .description {
        width: 100%;
        font-size: 2rem;
    }
    #my-work .header1  {
        margin: 10px 0px 10px 0px;
        font-size: 2rem;
    }

    #my-work .project-block-left, #my-work .project-block-right {
        display: flex;
        flex-direction: column;
    }

    #my-work .project-block-right {
        display: flex;
        flex-direction: column-reverse;
    }


    #my-work .cover-image, .project-block-left .project-info, .project-block-right .project-info {
        width: 100%;
        justify-content: flex-start;
    }

    .project-block-left .description .paragraph1, .project-block-right .description .paragraph1 {
        font-size: 18px;
        font-weight: light;
        font-family: "HelveticaNeueLight";
        color: #454545;
        line-height: 36px;
    }

}

@media screen and (max-width:900px) {
    #my-work .display {
        margin: 10px 0px 10px 0px;
        font-size: 6rem;
    }

    #my-work .header1 {
        margin: 10px 0px 10px 0px;
      font-size: 2rem;
    }
}

@media screen and (max-width:500px) {
    #my-work .display {
        margin: 10px 0px 10px 0px;
        font-size: 4rem;
    }

    #my-work .header1 {
        margin: 10px 0px 10px 0px;
      font-size: 2rem;
    }
}
