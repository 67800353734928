#sketchbook-all {
    margin: 90px 90px 90px 90px;
}

#sketchbook-all .display {
    max-width: 50%;
}

@media screen and (max-width:900px) {
    #sketchbook-all {
        margin: 45px 45px 45px 45px;
    }
    #sketchbook-all .heading1 {
        font-size: 7rem ;
        margin: 30px 0px 30px 0px;
    }
    #sketchbook-all .subheading {
        font-size: 2rem;
        margin-bottom: 20px;
    }
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
    #sketchbook-all {
        margin: 45px 45px 45px 45px;
    }
    #focus-bg .placeholder {
        height: 80%;
        display: block;
        margin: auto auto auto auto;
    }
}

@media screen and (max-width:700px) {
    #sketchbook-all {
        margin: 30px 30px 30px 30px;
    }
    #sketchbook-all .heading1 {
        font-size: 3rem ;
    }
    #sketchbook-all .subheading {
        font-size: 2rem;
        margin-bottom: 20px;
    }
    #sketchbook-all .display {
        font-size: 6rem;
    }

}

@media screen and (max-width:500px) {
    #sketchbook-all {
        margin: 20px 20px 20px 20px;
    }
    #sketchbook-all .heading1 {
        font-size: 3rem ;
    }
    #sketchbook-all .subheading {
        font-size: 2rem;
        margin-bottom: 20px;
    }
    .grid-container {
        display: grid;
        grid-template-columns: 1fr 1fr;
    }
    #focus-bg .placeholder {
        height: 60%;
        display: block;
        margin: auto auto auto auto;
    }
}
